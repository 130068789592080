<template>
  <v-form ref="form" class="duplBox">
    <v-text-field
      :label="checkType"
      ref="refDuplInput"
      v-model="checkValue"
      :type="checkInputType"
      :placeholder="checkType"
      @input="valueChange"
      clearable
    />
    <v-btn :disabled="duplicateChecked" @click="checkDupl">check</v-btn>
  </v-form>
</template>
<script>
import { mapActions } from "vuex";
const rulesService = require("@/utils/rules.js");

export default {
  props: {
    userData: {
      type: [Array, Object],
    },
    value: {
      type: [String],
    },
    checkedValue: {
      type: [String],
    },
    checkInputType: {
      type: [String],
    },
    checkType: {
      type: [String],
    },
    prosDuplicateChecked: {
      type: [Boolean],
      default: false,
    },
  },
  watch: {
    value(val, oldVal) {
      this.checkValue = this.value;
    },
    prosDuplicateChecked(val, oldVal) {
      this.duplicateChecked = this.prosDuplicateChecked;
    },
  },
  data() {
    return {
      checkValue: this.value,
      duplicateChecked: this.prosDuplicateChecked,
      cellRules: rulesService.cellRules(),
      SSNRules: rulesService.SSNRules(),
      idRules: rulesService.idRules(),
    };
  },
  methods: {
    valueChange(e) {
      console.log("checkedValue : ", this.checkedValue);
      console.log("e : ", e);
      // return;
      if (this.checkedValue === e) {
        this.duplicateChecked = true;
        this.changeDuplCheckValue(true, this.checkedValue);
        return;
      }
      this.duplicateChecked = false;
      this.changeDuplCheckValue(false, e);
      this.$emit("changeDuplicateChecked", this.duplicateChecked);
    },
    checkDupl() {
      // 필수
      if (!this.$refs.form.validate()) return;

      const D = this;
      let RF = this.$refs;

      // ssn / mobile일때
      let chk = this.SSNRules;
      if (this.checkType === "SSN" || this.checkType === "mobile") {
        if (this.checkType === "mobile") chk = this.cellRules;
        if (
          !this.$helper.validCheck(
            chk.required(D.checkValue),
            RF.refDuplInput,
            chk.required(D.checkValue)
          )
        )
          return;
        if (
          !this.$helper.validCheck(
            chk.counter(D.checkValue),
            RF.refDuplInput,
            chk.counter(D.checkValue)
          )
        )
          return;
        if (this.checkType === "SSN")
          if (
            !this.$helper.validCheck(
              chk.check(D.checkValue),
              RF.refDuplInput,
              chk.check(D.checkValue)
            )
          )
            return;
      }

      // id일때
      if (this.checkType === "loginId") {
        chk = this.idRules;
        if (
          !this.$helper.validCheck(
            chk.required(D.checkValue),
            RF.refDuplInput,
            chk.required(D.checkValue)
          )
        )
          return;
        if (
          !this.$helper.validCheck(
            chk.email(D.checkValue),
            RF.refDuplInput,
            chk.email(D.checkValue)
          )
        )
          return;
      }

      const reqData = {
        endpoint: "patient/duplCheck",
        hospital_id: this.userData.hospital_id,
        checkType: this.checkType,
        checkValue: this.checkValue,
      };
      this.getDataQuery(reqData).then((res) => {
        if (res.result) {
          this.$helper.showTostMessage(
            "",
            "already in use",
            "info"
          );
          return;
        }
        this.duplicateChecked = true;
        this.changeDuplCheckValue(true, this.checkValue);
        this.$helper.showTostMessage("", "available", "success");
      });
    },
    changeDuplCheckValue(option, value) {
      if (this.checkType === "SSN") {
        this.userData.ssn = value;
        this.userData.duplicateSSNChecked = option;
      }
      if (this.checkType === "mobile") {
        this.userData.cell = value;
        this.userData.duplicateMobileChecked = option;
      }
      if (this.checkType === "loginId") {
        this.userData.loginId = value;
        this.userData.duplicateLoginIdChecked = option;
      }
    },
    ...mapActions(["getDataQuery"]),
  },
};
</script>
<style lang="scss">
.duplBox {
  display: flex;
}
</style>