<template>
  <v-card-actions class="d-flex flex-reverse align-center mb-3 justify-end">
    <v-btn v-if="isShowBtnProduct" color="primary" depressed large @click="managerProduct">
      {{ $t('common.button.managerProduct') }}</v-btn
    >
    <v-btn color="primary" depressed large @click="save">
      {{ dataId > 0 ? $t('common.button.update') : $t('common.button.save') }}</v-btn
    >
    <v-btn
      v-if="dataId > 0 && activated"
      color="primary"
      outlined
      large
      @click="active(false)"
    >
      {{ $t('common.button.unActive') }}
    </v-btn>
    <v-btn
      v-if="dataId > 0 && !activated"
      color="primary"
      outlined
      large
      @click="active(true)"
    >
      {{ $t('common.button.active') }}
    </v-btn>
  </v-card-actions>
</template>
<script>
export default {
  props: {
    isShowBtnProduct: {
      type: Boolean,
      default: false
    },
    dataId: {
      type: Number
    },
    activated: {
      type: Boolean
    }
  },
  methods: {
    managerProduct() {
      this.$emit("managerProduct")
    },
    save() {
      this.$emit("save")
    },
    active(option) {
      let msg = option ? '' : 'Un';
      if (!confirm(`${msg}Active?`)) return;
      this.$emit("active", option)
    },
  },
};
</script>
<style lang="">
</style>