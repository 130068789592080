<template>
  <div :class="prosIsRow? 'classInline':''">
    <v-radio-group :label="label" v-model="selected" :disabled="prosDisabled">
      <v-radio
        v-for="(item, index) in items"
        :style="propsVGap"
        :key="index"
        :color="item.color"
        :label="item.label"
        :value="item.value"
        @change="changeType(item)"
      />
    </v-radio-group>
  </div>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    prosSelected: {
      type: String,
      default: "",
    },
    items: {
      type: [Object, Array],
    },
    prosDisabled: {
      type: Boolean,
      default: false,
    },
    propsVGap: {
      type: String,
      default: "margin-bottom: 8px"
    },
    prosIsRow: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    prosSelected(val) {
      this.selected = val;
    },
  },
  data() {
    return {
      selected: this.prosSelected,
    };
  },
  methods: {
    changeType(e) {
      this.$emit("changeType", e.value);
    },
  },
};
</script>
<style lang="scss">
.checkBoxLayout {
  flex-direction: row !important;
}
.classInline {
  .v-input--radio-group--column .v-input--radio-group__input {
    flex-direction: row !important;
    align-items: baseline;
  }
}
.v-radio {
  padding-right: 15px !important;
}

.v-input__control {
  padding-right: 15px;
}
.v-input--selection-controls {
  margin-top: 0 !important;
  padding-top: 0 !important;
}
</style>