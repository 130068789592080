<template>
  <v-select
    :chips="chips"
    :style="selectStyleWidth"
    :value="value"
    :items="items"
    :label="label"
    :item-text="item_text"
    :item-value="item_value"
    :disabled="disabled"
    :readonly="readonly"
    :multiple="multiple"
    :rules="required ? [rules.ko_requiredRule] : []"
    dense
    variant="solo"
    @input="updateValue"
    @change="changeSelect"
  >
    <template v-if="isMultipleLine" v-slot:selection="{ item, index }">
      <span v-if="index === 0"> {{ value.length }} Selected </span>
    </template>
  </v-select>
</template>

<script>
import { requiredRule, ko_requiredRule } from "@/utils/rules";
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    items: {
      type: [Object, Array],
    },
    selectStyleWidth: {
      type: String,
      default: "width: 200px",
    },
    value: {
      type: [Number, String, Array],
    },
    item_text: {
      type: String,
      default: "text",
    },
    item_value: {
      type: String,
      default: "value",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    isMultipleLine: {
      type: Boolean,
      default: false,
    },
    chips: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rules: {
        requiredRule,
        ko_requiredRule,
      },
    };
  },
  methods: {
    changeSelect(e) {
      this.$emit("changeSelect", e);
    },
    updateValue(data) {
      this.$emit("input", data);
    },
  },
};
</script>

<style lang="scss">
.v-select__slot > .v-select__selections {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.v-autocomplete.v-input > .v-input__control > .v-input__slot {
  // padding: 5px 0;
}
</style>
