<template>
  <SelectBox
    v-if="userType === 'super'"
    :selectStyleWidth="selectStyleWidth"
    :value="select"
    :items="list"
    @changeSelect="updateSelect"
  />

  <span class="clinicOne" v-else>
    <!-- <b>{{ hospitalName }}</b> -->
  </span>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import SelectBox from "@/components/commonV2/ui/SelectBox.vue";

export default {
  components: {
    SelectBox,
  },
  props: {
    selectStyleWidth: {
      type: String,
      default: "width: 200px",
    },
  },
  data() {
    return {
      select: "",
      list: null,
      userType: "",
      hospitalName: "",
      reqData: {
        endpoint: "hospital",
        activated: true,
        searchVal: "",
      },
    };
  },
  mounted() {
    if (this.meInfo === null) return;
    this.userType = this.meInfo.userType;
    this.hospitalName = this.meInfo.hospitalName;
    if (this.userType !== "super") return;
    this.getData();
  },
  methods: {
    getData() {
      const $h = this.$helper;
      this.list = [];
      const b = { value: "", text: this.$t('select.clinic') };
      this.list.push(b);
      this.getDataQuery(this.reqData).then((res) => {
        if (res === undefined) return;
        res.data.forEach((element) => {
          const d = { value: element.hospital_id, text: $h.definedStringI18($h.makeStringToArray(element.hospitalName, "@@"), 'kr') };
          this.list.push(d);
        });
      });
    },
    updateSelect(e) {
      this.select = e;
      this.$emit("updateSelect", e);
    },
    ...mapActions(["getDataQuery"]),
  },
  computed: {
    ...mapGetters(["meInfo"]),
  },
};
</script>
<style lang="scss">
.clinicOne {
  font-size: 20px;
  padding: 17 17 17 0 !important;
}
</style>