export const okchangePassWordAlertMessage = () => "Password changed!";

export const errorAlertMessage = () => "Error Occurred!";

export const requiredFieldAlertMessage = () => "은(는) 필수입니다";

export const requiredFieldAlertMessage_ko = () => " 을(를) 입력해주세요";

export const formatAlertMessage = () => "The mobile number does not match the format";

export const medicineRequired = () => {
  return ["startDay", "startTime", "amount"];
};

export const clinicRequired = () => {
  return [
    "clinicName",
    "clinicType",
    "clinicType2",
    "phone",
    // 'email',
    "address1",
    "city",
    "state",
    "zipcode",
    // 'country',
    "active",
    "context"
  ];
};
export const clinicRequired_ko = () => {
  return [
    "clinicName",
    // "clinicType",
    "clinicType2",
    "phone",
    // 'email',
    "zipcode",
    "address1",
    "address2",
    // "city",
    // "state",
    // 'country',
    "active",
    "context"
  ];
};

export const clinicStaffRequired = () => {
  return [
    "firstName",
    "lastName",
    "staffType",
    // 'password',
    // 'passwordConfirm',
    "email"
  ];
};
export const clinicStaffRequired_ko = () => {
  return [
    "firstName",
    // 'lastName',
    "staffType",
    // 'password',
    // 'passwordConfirm',
    "email"
  ];
};
export const patientRequired = () => {
  return [
    "mrn",
    "firstName",
    "lastName",
    "dayOfBirth",
    "gender",
    // "agreement",
    "ssn",
    "was99453ever",
    "country",
    "mobile",
    // 'phone',
    // 'email',
    // 필수제외 2022.01.18
    // 'emergencyContactName',
    // 'emergencyContact',
    // 'emergencyRelationship',
    "clinicId",
    "zipcode",
    "city",
    "state",
    "primaryPhysicianPk",
    "address1",
    // "icdCode",
    "diagnosis"
    // "lastVisitDay",
  ];
};
export const patientRequired_ko = () => {
  return [
    // "mrn",
    // "firstName",
    // "lastName",
    // "dayOfBirth",
    // "gender",
    // "agreement",
    "country",
    // "mobile",
    // 'phone',
    // 'email',
    // 필수제외 2022.01.18
    // 'emergencyContactName',
    // 'emergencyContact',
    // 'emergencyRelationship',
    "clinicId",
    // "zipcode",
    // "city",
    // "state",
    "primaryPhysicianPk"
    // "address1"
    // "icdCode",
    // "diagnosis"
    // "lastVisitDay",
  ];
};
export const deviceRequired = () => {
  return ["deviceID", "deviceType"];
};

export const medicineInputRequired = () => {
  return ["DRUG_NAME", "DOSAGE_FORM"];
};

export const forgetPwRequired = () => {
  return ["firstName", "lastName", "email"];
};

export const forgetPwRequired_ko = () => {
  return ["firstName", "email"];
};
