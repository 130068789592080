<template>
  <div class="nameBox">
    <v-text-field
      :label="$t('common.component.names.fname')"
      :placeholder="$t('common.component.names.fname')"
      ref="refFName"
      v-model="data.fname"
      :rules="[nameRules.required, nameRules.min]"
    />
    <v-text-field
      :label="$t('common.component.names.mname')"
      :placeholder="$t('common.component.names.mname')"
      ref="refNName"
      v-model="data.mname"
    />
    <v-text-field 
      :label="$t('common.component.names.lname')"
      :placeholder="$t('common.component.names.lname')"
      ref="refLName" 
      v-model="data.lname" 
      :rules="[nameRules.required, nameRules.min]"
    />
  </div>
</template>
<script>
const rulesService = require("@/utils/rules.js");
export default {
  props: {
    data: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      nameRules: rulesService.nameRules(),
    }
  },
};
</script>
<style lang="scss">
.nameBox {
  display: flex;
  width: auto;
}
</style>