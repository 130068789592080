<template>
  <v-card-actions
    class="top-actions d-flex flex-reverse align-center justify-end mt-2"
  >
    <v-btn icon @click="close">
      <v-icon dark>mdi-close</v-icon>
    </v-btn>
  </v-card-actions>
</template>
<script>
export default {
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="">
</style>