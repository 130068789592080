<template>
  <v-card-text>
    <v-form ref="form">
      <v-row>
        <v-col>
          <SelectBox
            :label="$t('staff.detail.type')"
            ref="refStaffType"
            :items="staffTypeList"
            :value="select"
            @changeSelect="updateSelectStaffType"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <DuplCheckType
            checkInputType="string"
            checkType="loginId"
            :checkedValue="userData.checkedLoginId"
            :value="userData.loginId"
            :prosDuplicateChecked="userData.duplicateLoginIdChecked"
            :userData="userData"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            :label="$t('staff.detail.password')"
            :placeholder="$t('staff.detail.password')"
            ref="refPassword"
            type="password"
            v-model="userData.password"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <InputName3Type ref="refName" :data="userData" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <RadioComps
            :items="genderData"
            :prosSelected="genderValue"
            @changeType="radioCompsChangeType"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            :label="$t('staff.detail.mobile')"
            :placeholder="$t('staff.detail.mobile')"
            ref="refCell"
            v-model="userData.cell"
            :rules="[cellRules.required, cellRules.min, cellRules.max]"
          />
        </v-col>
      </v-row>
    </v-form>
  </v-card-text>
</template>
<script>
import SelectBox from "@/components/commonV2/ui/SelectBox.vue";
import DuplCheckType from "@/components/commonV2/ui/DuplCheckType.vue";
import InputName3Type from "@/components/commonV2/ui/InputName3Type.vue";
import RadioComps from "@/components/commonV2/ui/RadioComps.vue";
const formatService = require("@/utils/format.js");
const rulesService = require("@/utils/rules.js");

export default {
  components: {
    SelectBox,
    DuplCheckType,
    InputName3Type,
    RadioComps,
  },
  props: {
    userData: {
      type: [Object, Array],
    },
  },
  watch: {
    userData(val) {
      this.select = val.staffType;
    },
  },
  data() {
    return {
      cellRules: rulesService.cellRules(),
      select: this.userData.staffType,
      genderValue: "MALE", // 초기값
      genderData: formatService.genderTypeItems(),
      staffTypeList: formatService.staffType(),
    };
  },
  methods: {
    updateSelectStaffType(e) {
      console.log("", e);
      this.select = e;
      this.userData.staffType = e;
    },
    radioCompsChangeType(e) {
      this.userData.gender = e;
    },
  },
};
</script>
<style lang="">
</style>