<template>
  <div class="resultBox">
    <div class="label-group">
      {{ $t("common.resultLabel") }} : {{ data && data.length }}
    </div>
    <div class="switchBox">
      <v-switch
        v-if="IsSwitchShow"
        class="v-switch"
        v-model="switchSelect"
        :value="switchLabel"
        :label="switchLabel"
        @change="onChangeSwitch"
      />
      <div class="btnBox" v-if="btnIsShow">
        <v-btn color="primary" depressed small @click="clickEditorBtn">
          {{ btnLabel }}
        </v-btn>
        <div class="btnGap"></div>
        <v-btn
          v-if="xlsxBtnIsShow"
          color="primary"
          depressed
          small
          @click="clickXlsxBtn"
        >
          Excel Download
        </v-btn>
        <div class="btnGap" v-if="xlsxBtnIsShow"></div>
        <v-btn color="primary" depressed small @click="clickReload">
          <v-icon>mdi-reload</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      default: null,
      type: Array,
    },
    xlsxBtnIsShow: {
      default: true,
      type: Boolean,
    },
    btnIsShow: {
      default: true,
      type: Boolean,
    },
    btnLabel: {
      default: "NEW",
      type: String,
    },
    IsSwitchShow: {
      default: false,
      type: Boolean,
    },
    switchLabel: {
      type: String,
      default: "",
    },
    ResultLabel: {
      default: "Result",
      type: String,
    },
  },
  watch: {
    switchLabel(val) {
      this.switchSelect = val === "List" ? [] : [val];
    },
  },
  data() {
    return {
      switchSelect: this.switchLabel === "" ? [] : [this.switchLabel],
    };
  },
  methods: {
    clickReload() {
      this.$emit("clickReload");
    },
    clickEditorBtn() {
      this.$emit("clickEditorBtn");
    },
    clickXlsxBtn() {
      this.$emit("clickXlsxBtn");
    },
    clickCreateBtn() {
      this.$emit("clickCreateBtn");
    },
    onChangeSwitch(e) {
      this.$emit("changeSwitch", e);
    },
  },
};
</script>
<style lang="scss">
.label-group {
  padding-top: 20px;
}
.resultBox {
  display: flex;
}
.switchBox {
  display: flex;
  align-items: center;
  margin-left: auto;
  .v-switch {
    padding-top: 10px !important;
  }
}
.btnBox {
  display: flex;
  padding-left: 15px;
  .btnGap {
    padding-right: 3px;
  }
}
.is-active {
  display: inline-block;
  vertical-align: middle;
  padding: 0 10px;
}
</style>