<template>
  <v-row>
    <v-col class="textSearch">
      <v-text-field
        ref="txtSearchWord"
        id="txtSearchWord"
        :placeholder="placeholder"
        v-model="searchWorld"
        @change="changeText"
        @keyup.enter="changeWord"
      />
    </v-col>
    <v-col>
      <v-btn color="primary" depressed small @click="clickSearch">{{ $t('common.button.search') }}</v-btn>
      <v-btn outlined depressed small @click="clickReset" v-if="searchWorld !== ''">
        {{ $t('common.button.searchClear') }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import $ from "jquery";
export default {
  props: {
    propsSearchWorld: "",
    placeholder: {
      type: String,
      default: "Search",
    },
  },
  data() {
    return {
      searchWorld: "",
    };
  },
  watch: {
    propsSearchWorld() {
      this.searchWorld = this.propsSearchWorld;
    },
  },
  methods: {
    changeText(e) {
      this.$emit("changeText", e);
    },
    clickReset() {
      this.searchWorld = "";
      this.clickSearch();
    },
    changeWord(e) {
      this.searchWorld = e.target.value;
      this.clickSearch();
    },
    clickSearch() {
      const sN = $("#txtSearchWord");
      if (this.searchWorld === '') {
        this.$helper.showTostMessage("", 'Search word is required', "error");
        sN.focus();
        return;
      }
      this.$emit("changeSearchWordComplete", this.searchWorld);
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/common";
.textSearch {
  padding-top: 20px;
}
.page-top-ui {
  .ant-row {
    .ant-col {
      .ant-input {
        width: 100%;
      }
      .ant-btn {
        min-width: 100%;
      }
      .ant-select {
        width: 100%;
      }
    }
  }
}
</style>