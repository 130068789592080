<template>
  <v-dialog v-model="visible" max-width="900px">
    <v-card class="editorPadding">
      <Header @close="close" />

      <v-card-title>
        {{ $t('staff.title') }} 
        {{ userData && userData.user_id > 0 ?  $t('common.titleUpdate') : $t('common.titleRegistration') }}
      </v-card-title>

      <StaffAddForm ref="refStaffForm" :userData="userData" />

      <BtnBox 
        :dataId="userData.user_id" 
        :activated="userData.activated"
        @save="saveUser"
        @active="activeUser" />
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Header from "./comp/Header.vue";
import BtnBox from "./comp/BtnBox.vue";
import StaffAddForm from "./form/StaffAddForm.vue";
const modelService = require("@/utils/dataModel");
const requireService = require("@/utils/requiredValue.js");
const rulesService = require("@/utils/rules.js");

export default {
  components: {
    Header,
    BtnBox,
    StaffAddForm,
  },
  data() {
    return {
      visible: false,
      idRules: rulesService.idRules(),
      passwordRules: rulesService.passwordRules(),
      requiredMsg: requireService.requiredFieldAlertMessage(),
      reqData: modelService.reqDataModel(),
      userData: modelService.staffModel(),
    };
  },
  methods: {
    Open(userData) {
      console.log("userData", userData);

      this.visible = true;
      this.reqData.user_id = userData.user_id; // READ
      this.userData.hospital_id = userData.hospital_id; // SAVE 필요
      if (userData.user_id > 0) {
        this.getStaffData();
      } else {
        this.userData = modelService.staffModel();
        this.userData.hospital_id = userData.hospital_id; // SAVE 필요
      }
    },
    close() {
      this.visible = false;
    },
    /** 데이터읽기  */
    async getStaffData() {
      this.reqData.endpoint = "staffOne";
      await this.getDataQuery(this.reqData).then((res) => {
        if (res === undefined) return;
        let resData = res.data;
        resData.password = "";
        resData.checkedLoginId = resData.loginId;
        resData.duplicateLoginIdChecked = true;
        this.userData = resData;
        console.log("", resData);
      });
    },
    /** 저장/수정/삭제  */
    async saveUser() {
      const $h = this.$helper;
      const D = this.userData;
      let RF = this.$refs;
      let chk;
      let tOj;
      let msg;
      console.log("", D);

      // 필수 체크
      RF = RF.refStaffForm.$refs;
      msg = `Staff Type ${this.requiredMsg}`;
      if (!$h.requiredCheck(D.staffType, RF.refStaffType, msg, false))
        return;

      chk = this.idRules;
      if (!$h.validCheck(chk.required(D.loginId),null,chk.required(D.loginId))) return;
      if (!$h.validCheck(chk.email(D.loginId),null,chk.email(D.loginId))) return;

      // loginId중복확인
      console.log("", D);
      if (!D.duplicateLoginIdChecked) {
        msg = `Please check for duplicates Login Id.`;
        $h.showTostMessage("",msg,"error");
        return;
      }

      // 신규시만 처리 | 수정시에 비번이 입력된 경우 -> 비번 변경의도가 있는경우
      if (D.user_id === 0 || D.password !== "") {
        chk = this.passwordRules;
        if (!$h.validCheck(chk.required(D.password),RF.refPassword,chk.required(D.password))) return;
        if (!$h.validCheck(chk.counter(D.password),RF.refPassword,chk.counter(D.password))) return;
      }

      if (!this.$refs.refStaffForm.$refs.form.validate()) return;

      console.log("", D);
      const saveLbl = D.user_id > 0 ? "Update" : "Register";
      if (!confirm(`Would you like to ${saveLbl}?`)) return;
      this.saveAction(D);
    },
    activeUser(option) {
      this.userData.activated = option;
      this.saveAction(this.userData);
    },
    async saveAction(data) {
      const $h = this.$helper;
      data.endpoint = "staff";
      await this.saveDataQuery(data).then((res) => {
        if (res === undefined) return;
        $h.showTostMessage("",$h.commonMessage("S"),"success");
        this.visible = false;
        this.$emit("complete", res);
      });
    },
    ...mapActions(["getDataQuery", "saveDataQuery"]),
  },
};
</script>
<style lang="scss">
.v-input {
  padding-top: 0 !important;
}
.row-title {
  font-size: 16px;
  font-weight: 700;
}
</style>
