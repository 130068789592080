var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"fixed-header":_vm.isFixHeader,"height":_vm.propsHeight,"loading":_vm.loading,"loadingText":_vm.loadingText,"headers":_vm.columns,"items":_vm.inputData,"search":_vm.search,"item-key":_vm.tableKey,"show-select":_vm.showSelect,"single-select":_vm.singleSelect,"hide-default-footer":_vm.footerHide,"items-per-page":_vm.perPage},on:{"click:row":_vm.clickRow,"item-selected":_vm.itemsSelected,"pagination":_vm.pagination},scopedSlots:_vm._u([{key:"item.editOpen",fn:function(ref){
var item = ref.item;
return [_c('span',{on:{"click":function($event){return _vm.editClick(item, 'patient')}}},[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}},{key:"item.memo",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.patientMemo)},on:{"click":function($event){return _vm.memoClick(item, 'patient')}}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{on:{"click":function($event){return _vm.nameFullClick(item, 'patient')}}},[_vm._v(_vm._s(item.nameFull))])]}},{key:"item.fileOriginalName",fn:function(ref){
var item = ref.item;
return [_c('span',{on:{"click":function($event){return _vm.imageClick(item, 'uploadFiles')}}},[_vm._v(_vm._s(item.fileOriginalName))])]}},{key:"item.consentRemoveBtn",fn:function(ref){
var item = ref.item;
return [_c('span',{on:{"click":function($event){return _vm.RemoveClick(item, 'uploadFiles')}}},[_vm._v("Remove")])]}},{key:"item.bookingTypeValue",fn:function(ref){
var item = ref.item;
return [_c('SelectBox',{attrs:{"selectStyleWidth":"width: 100%","value":item.bookingTypeValue,"items":_vm.itemsBookingType,"multiple":false},on:{"changeSelect":function($event){return _vm.changeSelectBookingType($event, item)}}})]}},{key:"item.typeAction",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil-box-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }