<template>
  <div>
    <!-- UI처리  -->
    <v-data-table 
      v-model="selected" 
      :fixed-header="isFixHeader"
      :height="propsHeight"
      :loading="loading" 
      :loadingText="loadingText" 
      :headers="columns" 
      :items="inputData" 
      :search="search" 
      :item-key="tableKey"
      :show-select="showSelect" 
      :single-select="singleSelect" 
      :hide-default-footer="footerHide" 
      :items-per-page="perPage" 
      @click:row="clickRow" 
      @item-selected="itemsSelected"
      @pagination="pagination">
      <template v-slot:item.editOpen="{ item }">
        <span @click="editClick(item, 'patient')">
          <v-icon>mdi-dots-vertical</v-icon>
        </span>
      </template>

      <template v-slot:item.memo="{ item }">
        <span @click="memoClick(item, 'patient')" v-html="item.patientMemo">
        </span>
      </template>

      <template v-slot:item.name="{ item }">
        <span @click="nameFullClick(item, 'patient')">{{ item.nameFull }}</span>
      </template>

      <template v-slot:item.fileOriginalName="{ item }">
        <span @click="imageClick(item, 'uploadFiles')">{{
          item.fileOriginalName
        }}</span>
      </template>

      <!-- file remove btn -->
      <template v-slot:item.consentRemoveBtn="{ item }">
        <span @click="RemoveClick(item, 'uploadFiles')">Remove</span>
      </template>

      <!-- 병원별 예약타입 콤보 -->
      <template v-slot:item.bookingTypeValue="{ item }"> 
        <SelectBox selectStyleWidth="width: 100%" :value="item.bookingTypeValue" :items="itemsBookingType" :multiple="false"
          @changeSelect="changeSelectBookingType($event, item)" />
      </template>

      <!-- 공통 상품타입/아이템관리 편집아이콘 -->
      <template v-slot:item.typeAction="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="success" dark v-bind="attrs" v-on="on" icon>
              <v-icon>mdi-pencil-box-outline</v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import SelectBox from "@/components/commonV2/ui/SelectBox.vue";
const formatService = require("@/utils/format.js");
export default {
  components: { SelectBox },
  props: {
    search: {
      type: String,
      default: "",
    },
    columns: {
      type: Array,
      default: () => [],
    },
    inputData: {
      type: Array,
      default: () => [],
    },
    tableKey: {
      type: String,
      default: "",
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
    footerHide: {
      type: Boolean,
      default: true,
    },
    singleSelect: {
      type: Boolean,
      default: false,
    },
    perPage: {
      type: Number,
      default: 5,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingText: {
      type: String,
      default: "Loading...",
    },
    isFixHeader: {
      type: Boolean,
      default: false,
    },
    propsHeight: {
      type: String,
      default: "100%",
    },
  },
  watch: {
    search() {
      // console.log(this.search);
    },
  },
  data() {
    return {
      selected: [],
      itemsBookingType: formatService.itemsBookingType(),
    };
  },
  methods: {
    clickRow(value) {
      // console.log("value", value);
      // console.log("userType", value.userType);
      // 중복 클릭방지
      if (value.userType === "patient") return;
      if (value.userType === "uploadFiles") return;
      this.$emit("clickRow", value);
    },
    editClick(item, mode) {
      this.$emit("clickRow", item);
    },
    memoClick(item, mode) {
      if (mode === undefined) return;
      console.log("memoClick", item);
      this.$emit("memoClick", item);
    },
    nameFullClick(item, mode) {
      if (mode === undefined) return;
      this.$emit("nameClick", item);
    },
    imageClick(item) {
      console.log("", item);
      this.$emit("imageClick", item);
    },
    RemoveClick(item) {
      console.log("", item);
      this.$emit("RemoveClick", item);
    },
    itemsSelected(value) {
      console.log("itemsSelected");
      this.$emit("itemsSelected", value);
    },
    pagination(e) {
      this.$emit("pagination", e);
    },
    selectedReset() {
      this.selected = [];
    },
    changeSelectBookingType(e, item) {
      item.bookingTypeValue = e;
      console.log("e", e, item);
    },
  },
};
</script>
